/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - Muzeum & média"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1o6iuzj --center pt--60" style={{"paddingBottom":350}} name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0619ebba5b8d4b1b93137261139b6573_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"2"} animS={"3"}>
              
              <Title className="title-box title-box--center fs--43 w--900" style={{"maxWidth":700}} content={"Muzeum &amp; média<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="js-anim  --anim3 --anim-s3 pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"bpjl4e7ddx"} animS={"3"} lightbox={false}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/81b34d7dd64445b8a6518796fec8524c_s=350x_.png"} url={"https://edu.ceskatelevize.cz/video/13433-frydek-mistek-muzeum-meteoritu"} use={"url"} href={"https://edu.ceskatelevize.cz/video/13433-frydek-mistek-muzeum-meteoritu"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/81b34d7dd64445b8a6518796fec8524c_s=350x_.png 350w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<a href=\"https://edu.ceskatelevize.cz/video/13433-frydek-mistek-muzeum-meteoritu\" target=\"_blank\" style=\"font-weight: bold;\">Toulavá kamera v Muzeu meteoritů</a>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">\"Toulavka\" muzeu velice pomohla. Skvělý pořad s širokou fanouškovskou základnou k nám přivedl spoustu nových turistů.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/8f6d40d2019f43f0b7d5e84a670b43ad_s=660x_.jpg"} url={"https://www.ceskatelevize.cz/porady/10435049455-dobre-rano/421236100071108/cast/874632/"} use={"url"} href={"https://www.ceskatelevize.cz/porady/10435049455-dobre-rano/421236100071108/cast/874632/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/8f6d40d2019f43f0b7d5e84a670b43ad_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/8f6d40d2019f43f0b7d5e84a670b43ad_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/8f6d40d2019f43f0b7d5e84a670b43ad_s=860x_.jpg 860w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"font-weight: bold;\"><a href=\"https://www.ceskatelevize.cz/porady/10435049455-dobre-rano/421236100071108/cast/874632/\">Představení Muzea / Dobré ráno</a></span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">Má první zkušenost s TV mimo Muzeum.<br>Po rozkliknutí vyber jeden z našich 3 vstupů.<br>Někdy přehrávání nefunguje, Google to jistí.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":655}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/2b8fe221bbd14d0d8722240db9ef96a5_s=860x_.jpg"} url={"https://www.ceskatelevize.cz/porady/12664259752-putovani-s-parou/321291310130008/#para"} use={"url"} href={"https://www.ceskatelevize.cz/porady/12664259752-putovani-s-parou/321291310130008/#para"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/2b8fe221bbd14d0d8722240db9ef96a5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/2b8fe221bbd14d0d8722240db9ef96a5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/2b8fe221bbd14d0d8722240db9ef96a5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/2b8fe221bbd14d0d8722240db9ef96a5_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"font-weight: bold;\"><a href=\"https://www.ceskatelevize.cz/porady/12664259752-putovani-s-parou/321291310130008/#para\" target=\"_blank\">Pepa Polášek a Putování s párou</a></span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">\"Vypadá to jako tlačenka\" :D<br>Pepa Polášek a jeho vtipná glosa jednoho z nejvzácnějších typů meteoritů.<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"ya4zwsw8qz"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/5a695e9b65154c6789e7aeb2ab7ed57d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/5a695e9b65154c6789e7aeb2ab7ed57d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/5a695e9b65154c6789e7aeb2ab7ed57d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/5a695e9b65154c6789e7aeb2ab7ed57d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/5a695e9b65154c6789e7aeb2ab7ed57d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/5a695e9b65154c6789e7aeb2ab7ed57d_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/6bac702d217343d59b7fe1809a0bd54f_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/6bac702d217343d59b7fe1809a0bd54f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/6bac702d217343d59b7fe1809a0bd54f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/6bac702d217343d59b7fe1809a0bd54f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/6bac702d217343d59b7fe1809a0bd54f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/6bac702d217343d59b7fe1809a0bd54f_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"7ode6kfaetu"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":300}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/74d3ab9b71134dd8bb633ef28fe94fad_s=660x_.png"} url={"https://www.idnes.cz/ostrava/zpravy/muzeum-meteoritu-sberatel-jaroslav-filip-frydek-mistek-meteorit.A211009_630864_ostrava-zpravy_pjen"} use={"url"} href={"https://www.idnes.cz/ostrava/zpravy/muzeum-meteoritu-sberatel-jaroslav-filip-frydek-mistek-meteorit.A211009_630864_ostrava-zpravy_pjen"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/74d3ab9b71134dd8bb633ef28fe94fad_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/74d3ab9b71134dd8bb633ef28fe94fad_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/74d3ab9b71134dd8bb633ef28fe94fad_s=860x_.png 860w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Gram meteoritu může stát více než zlato</span>"}>
              </Text>

              <Button className="btn-box" url={"https://www.idnes.cz/ostrava/zpravy/muzeum-meteoritu-sberatel-jaroslav-filip-frydek-mistek-meteorit.A211009_630864_ostrava-zpravy_pjen"} href={"https://www.idnes.cz/ostrava/zpravy/muzeum-meteoritu-sberatel-jaroslav-filip-frydek-mistek-meteorit.A211009_630864_ostrava-zpravy_pjen"} content={"Přečíst článek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":300}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/e50a6a23fdfb48619ce46df457e02cfe_s=860x_.jpg"} url={"https://www.blesk.cz/clanek/regiony-ostrava/693263/unikatni-muzeum-meteoritu-posly-nebes-si-tu-muzete-i-osahat.html"} use={"url"} href={"https://www.blesk.cz/clanek/regiony-ostrava/693263/unikatni-muzeum-meteoritu-posly-nebes-si-tu-muzete-i-osahat.html"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/e50a6a23fdfb48619ce46df457e02cfe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/e50a6a23fdfb48619ce46df457e02cfe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/e50a6a23fdfb48619ce46df457e02cfe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/e50a6a23fdfb48619ce46df457e02cfe_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Unikátní Muzeum meteoritů.<br>Posly z nebes si tu můžete i osahat.<br>Blesk<br></span>"}>
              </Text>

              <Button className="btn-box" url={"https://www.blesk.cz/clanek/regiony-ostrava/693263/unikatni-muzeum-meteoritu-posly-nebes-si-tu-muzete-i-osahat.html"} href={"https://www.blesk.cz/clanek/regiony-ostrava/693263/unikatni-muzeum-meteoritu-posly-nebes-si-tu-muzete-i-osahat.html"} content={"Přečíst článek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":300}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/1e500ac25278413f91a835278ecdf174_s=860x_.jpg"} url={"https://polar.cz/zpravy/frydeckomistecko/frydek-mistek/11000027211/navstevnici-noveho-muzea-meteoritu-ve-frydku-mistku-si-mohou-kameny-z-vesmiru-i-osahat"} use={"url"} href={"https://polar.cz/zpravy/frydeckomistecko/frydek-mistek/11000027211/navstevnici-noveho-muzea-meteoritu-ve-frydku-mistku-si-mohou-kameny-z-vesmiru-i-osahat"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/1e500ac25278413f91a835278ecdf174_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/1e500ac25278413f91a835278ecdf174_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/1e500ac25278413f91a835278ecdf174_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/1e500ac25278413f91a835278ecdf174_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/1e500ac25278413f91a835278ecdf174_s=2000x_.jpg 2000w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Návštěvníci nového Muzea meteoritů ve Frýdku-Místku si mohou kameny z vesmíru i osahat.<br>TV Polar<br></span>"}>
              </Text>

              <Button className="btn-box" url={"https://polar.cz/zpravy/frydeckomistecko/frydek-mistek/11000027211/navstevnici-noveho-muzea-meteoritu-ve-frydku-mistku-si-mohou-kameny-z-vesmiru-i-osahat"} href={"https://polar.cz/zpravy/frydeckomistecko/frydek-mistek/11000027211/navstevnici-noveho-muzea-meteoritu-ve-frydku-mistku-si-mohou-kameny-z-vesmiru-i-osahat"} content={"Podívat se na reportáž"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"7ode6kfaetu"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":300}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=660x_.jpg"} url={"https://fm.denik.cz/zpravy_region/vesmir-kohutka-perseidy-nebe-obloha-meteority-beskydy-pozorovani-20230809.html"} use={"url"} href={"https://fm.denik.cz/zpravy_region/vesmir-kohutka-perseidy-nebe-obloha-meteority-beskydy-pozorovani-20230809.html"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/3db73ac99cc64d459a0ec7707d5a6b66_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Víkendový vesmír v Beskydech: Perseidy na nebi, ale i meteority na louce.<br>Deník.cz</span><br>"}>
              </Text>

              <Button className="btn-box" url={"https://fm.denik.cz/zpravy_region/vesmir-kohutka-perseidy-nebe-obloha-meteority-beskydy-pozorovani-20230809.html"} href={"https://fm.denik.cz/zpravy_region/vesmir-kohutka-perseidy-nebe-obloha-meteority-beskydy-pozorovani-20230809.html"} content={"Přečíst článek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":505}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/fc483a6dc2ee4b7e9a3c192c255794e2_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/fc483a6dc2ee4b7e9a3c192c255794e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/fc483a6dc2ee4b7e9a3c192c255794e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/fc483a6dc2ee4b7e9a3c192c255794e2_s=860x_.jpg 860w"} lightbox={false} use={"url"} href={"https://www.blesk.cz/clanek/regiony-ostrava/783695/jaroslav-ma-unikatni-muzeum-meteoritu-kameny-starsi-nez-planeta-zeme.html"} url={"https://www.blesk.cz/clanek/regiony-ostrava/783695/jaroslav-ma-unikatni-muzeum-meteoritu-kameny-starsi-nez-planeta-zeme.html"} target={"_blank"}>
              </Image>

              <Text className="text-box text-box--center pl--0" style={{"maxWidth":562}} content={"<span style=\"font-weight: bold;\"><a href=\"https://www.blesk.cz/clanek/regiony-ostrava/783695/jaroslav-ma-unikatni-muzeum-meteoritu-kameny-starsi-nez-planeta-zeme.html\">Jaroslav má unikátní Muzeum meteoritů: Kameny starší než planeta Země</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":440}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/7da308ae037b4330ba7050c829b78ce2_s=860x_.jpg"} url={"https://emefka.sk/6-dovodov-preco-meteority-patria-k-tomu-najfascinujucejsiemu-co-priroda-ponuka/"} use={"url"} href={"https://emefka.sk/6-dovodov-preco-meteority-patria-k-tomu-najfascinujucejsiemu-co-priroda-ponuka/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/7da308ae037b4330ba7050c829b78ce2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/7da308ae037b4330ba7050c829b78ce2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/7da308ae037b4330ba7050c829b78ce2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/7da308ae037b4330ba7050c829b78ce2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/7da308ae037b4330ba7050c829b78ce2_s=2000x_.jpg 2000w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">6 důvodů, proč jsou meteority tak fascinující a jak to chodí ve světě prodejců? I ty můžeš mít ten svůj.<br>Emefka.sk<br></span>"}>
              </Text>

              <Button className="btn-box" url={"https://emefka.sk/6-dovodov-preco-meteority-patria-k-tomu-najfascinujucejsiemu-co-priroda-ponuka/"} href={"https://emefka.sk/6-dovodov-preco-meteority-patria-k-tomu-najfascinujucejsiemu-co-priroda-ponuka/"} content={"Přečíst článek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":439}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/2079dab43fea48408438d3073a96272b_s=660x_.jpg"} url={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/221411010100922/cast/865125/"} use={"url"} href={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/221411010100922/cast/865125/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/37955/2079dab43fea48408438d3073a96272b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/2079dab43fea48408438d3073a96272b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/2079dab43fea48408438d3073a96272b_s=860x_.jpg 860w"} lightbox={false}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Nové muzeum otevírá ve Frýdku-Místku.<br>Reportáž Studia 6<br>Česká televize<br></span>"}>
              </Text>

              <Button className="btn-box" url={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/221411010100922/cast/865125/"} href={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/221411010100922/cast/865125/"} content={"Podívat se na reportáž"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":6,"paddingBottom":0}} name={"135wk4gc115q"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.&nbsp; &nbsp;© 2024 Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}